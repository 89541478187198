<template>
  <b-overlay :show="isFetchingCmsSite">
    <b-row>
      <b-col class="text-right mb-2">
        <b-button
          variant="success"
          @click="$bvModal.show('cms-create-floating-menus')"
          >New Floating Menu</b-button
        >
      </b-col>
    </b-row>
    <b-table :fields="fields" :items="cmsFloatingMenus" responsive>
      <template #cell(#)="data">
        <div>
          {{ data.index + 1 }}
        </div>
      </template>
      <template #cell(imageSrc)="data">
        <b-img
          v-if="data.value"
          :src="data.value"
          width="auto"
          height="150px"
        ></b-img>
        <div v-else>-</div>
      </template>
      <template #cell(isExternalLink)="data">
        <b-badge v-if="data.value" variant="success">Yes</b-badge>
        <b-badge v-else variant="danger">No</b-badge>
      </template>
      <template #cell(externalLinkTo)="data">
        <div>
          {{ data.value || '-' }}
        </div>
      </template>
      <template #cell(internalLinkTo)="data">
        <div>
          {{ data.value || '-' }}
        </div>
      </template>
      <template #cell(id)="data">
        <div>
          <b-button
            size="sm"
            variant="danger"
            @click="onRemoveFloatingMenu(data.value)"
            >Remove</b-button
          >
        </div>
      </template>
    </b-table>
    <CreateCmsFloatingMenu />
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    CreateCmsFloatingMenu: () => import('./create-floating-menu.vue'),
  },
  data() {
    return {
      fields: [
        '#',
        {
          key: 'title',
          label: 'Title',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'imageSrc',
          label: 'Image',
        },
        {
          key: 'displayCondition',
          label: 'Display Condition',
        },
        {
          key: 'isExternalLink',
          label: 'External Link',
        },
        {
          key: 'internalLinkTo',
          label: 'Internal Link',
        },
        {
          key: 'externalLinkTo',
          label: 'External Link',
        },
        {
          key: 'position',
          label: 'Position',
        },
        {
          key: 'id',
          label: 'Actions',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['cmsFloatingMenus', 'siteId']),
    ...mapState({
      isFetchingCmsSite: (state) => state.cms.isFetchingCmsSite,
    }),
  },
  methods: {
    ...mapActions(['removeCmsFloatingMenu']),
    onRemoveFloatingMenu(floatingMenuId) {
      this.removeCmsFloatingMenu({ siteId: this.siteId, floatingMenuId })
    },
  },
}
</script>
